main.page__webinars {
  width: 100%;
  height: 100%;
}

button {
  cursor: pointer;
  transition: 0.3s all ease-in-out;
}


.webinars__block {
  width: 100%;
  min-height: 55vh;
  background-image: url('../../imgs/webinars_bg@3x.png');
  background-repeat: no-repeat;
  background-size: cover;
  margin-top: -100px;
}

.header__login {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.header.webinars {
  background-color: rgba(252, 253, 254, 0.61);
}

.header__login label {
  width: 141px;
  height: 31px;
  margin: 0 4px 7px 0;
  padding: 8px 8px 12px;
  border-radius: 15px;
  border: solid 1px #979797;
  background-color: #fff;
}

label input {
  width: 100%;
}

label.email {
  display: flex;
  flex-direction: column;
}

label.password {
  width: 218px;
}

label.email p {
  color: #006db7;
  font-size: 12px;
  width: 100px;
  margin-top: 10px;
}

.webinars__page-container {
  max-width: 60%;
  /* height: 30vh; */
  padding-top: 10%;
  margin: auto;
}

.webinars__desc {
  min-width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.webinars__desc h1 {
  font-size: 46px;
  line-height: 1.22;
  letter-spacing: 3px;
  color: #1f9cf4;
}

.webinars__desc span {
  width: 70%;
  font-size: 16px;
  font-weight: 300;
  text-transform: uppercase;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.75;
  letter-spacing: normal;
  text-align: center;
}

.webinars__desc p {
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.5px;
  text-align: center;
  color: #5f6367;
  margin-top: 3%;
}

.webinars__desc p b {
  color: #1f9cf4;
}

.members__container {
  width: 100%;
  min-height: 100%;
  padding-bottom: 100px;
  padding-top: 100px;
}

.members__content {
  width: 70%;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.members__block {
  width: 80%;
  min-height: 210px;
  margin: auto;
  padding: 16px;
  border-radius: 10px;
  border: solid 1px #f7f7f7;
  background-image: linear-gradient(to right, rgba(214, 226, 238, 0.1) 1%, rgba(248, 251, 254, 0.1) 100%);
  display: flex;
  align-items: center;
  margin-bottom: 32px;
}

.image-block {
  margin-right: 20px;
}

.members-desc p {
  font-size: 14px;
  font-weight: 300;
  line-height: 1.71;
  letter-spacing: 0.18px;
  color: #1f9cf4;
}

.members-desc {
  display: flex;
  flex-direction: column;
}

.members-desc span {
  width: 90%;
  font-size: 20px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: normal;
  margin-bottom: 16px;
}

button.view-webinar {
  width: 253px;
  height: 49px;
  border-radius: 26px;
  /* box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.2); */
  text-transform: uppercase;
  color: #137cef;
  border: 1px solid #137cef;
  background-color: #fff;
  text-transform: uppercase;
  letter-spacing: 2px;
}
button.view-webinar a {
  color: #137cef;
}
button.view-webinar:hover a {
  color: #fff;
}
button.view-webinar:hover {
  width: 253px;
  height: 49px;
  border-radius: 26px;
  box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.2);
  background-image: linear-gradient(to left, #27aff8, #1e82ca);
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 2px;
}
button.show-btn {
  width: max-content;
  /* height: 49px; */
  padding: 20px 50px;
  border-radius: 36px;
  /* box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.2); */
  color: #137cef;
  border: 1px solid #137cef;
  background-color: #fff;
  text-transform: uppercase;
  letter-spacing: 2px;
  margin-top: 42px;
}
button.show-btn:hover {
  width: max-content;
  /* height: 49px; */
  padding: 20px 50px;
  border-radius: 36px;
  box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.2);
  background-image: linear-gradient(to left, #27aff8, #1e82ca);
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 2px;
  margin-top: 42px;
}