@media screen and (max-width: 1180px) {
  .contacts__block {
    min-height: max-content;
  }

  .contacts__page-container {
    padding-top: 20%;
    height: max-content;
  }

  .form__content {
    min-width: 50%;
  }

  .footer__items a {
    font-size: 12px;
    text-align: center;
  }

  .footer__copyrights p {
    font-size: 12px;
    text-align: center;
  }

  .contact__form {
    padding-bottom: 0;
  }

  .webinars__page-container {
    padding-top: 30%;
  }

  .webinars__desc span {
    width: 100%;
    margin: 15px;
  }

  .members__container {
    padding-top: 0;
  }
  .members__content {
  }

  .members__content {
    width: 80%;
  }

  .members__block {
    width: 100%;
  }
}

@media screen and (max-width: 1200px) {
  .webinars__desc {
    padding-top: 10%;
  }

  .webinars__block {
    min-height: 0 !important;
  }
}

@media screen and (max-width: 440px) {
  .webinars__page-container {
    max-width: 90% !important;
  }

  .contacts__page-container {
    padding-top: 0;
  }

  nav.header__webinars {
    display: none;
  }

  nav .header__webinars img {
    display: flex;
  }

  .webinars__page-container {
    width: 100%;
  }

  .webinars__page-content {
    width: 100%;
  }

  .webinars__desc {
    width: 100%;
    padding-top: 40%;
  }

  .webinars__desc h1 {
    font-size: 32px !important;
  }

  .webinars__desc span {
    width: 90% !important;
    font-size: 14px !important;
    margin: 20px 0;
  }

  .view-webinar {
    align-self: center;
  }

  .members__container {
    width: 100%;
    padding-top: 35px !important;
  }

  .members__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 90% !important;
  }

  .members__block {
    width: 100% !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
  }

  .members-desc span {
    font-size: 18px;
  }

  .members__block img {
    width: 100%;
    margin-bottom: 10px;
  }

  .image-block {
    margin: 0;
  }

  .form__content {
    width: 100%;
  }

  .contacts__desc {
    width: 80%;
    padding-top: 100px;
  }

  .contacts__desc h1 {
    font-size: 32px;
    text-align: center;
  }

  .contacts__desc span {
    width: 90%;
  }

  .form__content h1 {
    font-size: 18px;
  }

  .contacts__desc h2 {
    font-size: 18px;
  }

  .footer__container {
    padding: 20px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .footer__logo {
    display: none;
  }

  .footer__items {
    display: none;
  }

  .footer__copyrights {
    width: 100%;
  }

  .footer__copyrights p {
    float: left;
  }

  .footer__container._container {
    width: 80%;
    border-top: solid 1px #dcdcdc;
    padding: 32px 0;
    margin-top: 52px;
  }

  .contact__form {
    padding-top: 32px;
    padding-bottom: 32px;
  }

  .webinars__page-container {
    padding-top: 0;
  }
}
