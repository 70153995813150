main.page__contact {
  width: 100%;
  height: 100%;
  flex: 1 0 0;
}

button {
  cursor: pointer;
  transition: 0.3s all ease-in-out;
}


.contacts__block {
  width: 100%;
  min-height: 55vh;
}


.contacts__page-container {
  min-width: 60%;
  min-height: 30vh;
  margin: auto;
}

.contacts__desc {
  width: 50%;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid #dcdcdc;
  padding-top: 5%;
}


.contacts__desc h1 {
  font-size: 46px;
  line-height: 1.22;
  letter-spacing: 3px;
  color: #1f9cf4;
}

.contacts__desc span {
  width: 53%;
  font-size: 16px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.75;
  letter-spacing: normal;
  text-align: center;
  margin-top: 16px;
}

.contacts__desc p {
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.5px;
  text-align: center;
  color: #5f6367;
  margin-top: 3%;
}

.email-block {
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
}

.contacts__desc h2 {
  font-size: 24px;
  text-align: center;
  color: #2f3d4d;
  margin: 16px 0 42px 0;
}

.contact__form {
  width: 100%;
  min-height: 40vh;
  padding: 20px 0 100px 0;
}

.form__container {
  min-width: 100%;
  height: 100%;
  margin: auto;
}

.form__content {
  width: 32%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: auto;
}

.form__content h1 {
  text-transform: uppercase;
  font-size: 24px;
  letter-spacing: 4.8px;
}

.contact-inputs {
  margin: 24px 32px;
}


input.contacts {
  width: 100%;
  height: 56px;
  margin: 8px 0;
  padding: 16px;
  border-radius: 4px;
  border: solid 1px #dcdcdc;
  background-color: #fff;
}

input.contacts#message {
  padding-bottom: 120px;
}

button.send-btn {
  min-width: 210px;
  height: 49px;
  border-radius: 26px;
  border: solid 1px #1177ee;
  background-color: #fff;
  color: #1177ee;
  text-transform: uppercase;
}

button.send-btn:disabled {
  border: solid 1px #dcdcdc;
  background-color: #fff;
  color: #dcdcdc;
  cursor: default;
}