@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;1,300&display=swap");

.page *,
.page *::before,
.page *::after {
  padding: 0;
  margin: 0;
  border: 0;
  box-sizing: border-box;
}

/* .page h1,
.page h2,
.page h3,
.page h4,
.page h5,
.page h6 {
  font-weight: inherit;
  font-size: inherit;
  color: #000;
} */

.wrapper {
  min-height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

._ibg {
  position: relative;
}

._ibg img {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  object-fit: cover;
}

._container {
  max-width: 1350px;
  padding: 0px 15px;
  margin: 0px auto;
  box-sizing: content-box;
}

.page {
  flex: 1 1 auto;
  height: 100%;
  line-height: 1;
  background: #fff;
  font-size: 14px;
  color: #252b42;
  font-weight: 500;
  font-family: "Lato", sans-serif;
}

.page img {
  vertical-align: top;
  margin: 0 auto;
}

.main-block {
  position: relative;
}

.main-block__body {
  position: relative;
  margin: auto;
  height: 80vh;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  justify-content: space-between;
}

.body-desc {
  max-width: 545px;
  /* margin-right: 100px; */
}

.main-block__title {
  font-size: 32px !important;
  font-weight: 300 !important;
  font-family: "Lato", sans-serif;
  line-height: 1.6;
  letter-spacing: 0.2px;
}

.main-block__text {
  font-size: 46px;
  font-weight: 300;
  font-family: "Lato", sans-serif;
  color: #17e;
  margin-top: 8px;
}

.main-block__desc {
  width: 545px;
  height: 104px;
  margin: 24px 0 0;
  font-size: 15px;
  font-weight: 300;
  font-family: "Lato", sans-serif;
  line-height: 1.6;
  letter-spacing: 0.2px;
  color: #000;
}

.main-block__image {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
}

.main-block__image img {
  position: relative;
  width: auto;
  height: auto;
}

@media (max-width: 1300px) {
  .main-block__image img {
    /* object-position: right; */
    object-fit: contain;
    width: 100%;
    height: 100%;
  }
}

button.order {
  width: 495px;
  height: 42px;
  border-radius: 5px;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.3);
  background-color: #006db7;
  color: #fff;
  font-size: 20px;
  text-transform: uppercase;
  font-weight: 500;
  font-family: "Lato", sans-serif;
  /* margin-top: 665px; */
  margin-top: 480px;
  margin-left: 100px;
  cursor: pointer;
  margin-bottom: 50px;
}

/* ....................Services block.................... */

.services__column {
  width: 48%;
  display: flex;
  justify-content: space-around;
  text-align: center;
  flex-wrap: wrap;
}

.services__body {
  width: 100%;
  display: flex;
  justify-content: space-between;
  justify-content: center;
  margin-top: 100px;
  margin-bottom: 100px;
}

.services__desc {
  width: 40%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.services__desc span {
  font-size: 20px;
  font-family: "Lato", sans-serif;
  color: #17e;
  margin-bottom: 16px;
  text-align: center;
  width: 380px;
}

.services__desc p {
  font-size: 16px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  font-family: "Lato", sans-serif;
  line-height: 1.5;
  letter-spacing: 0.2px;
  text-align: center;
  color: #000;
  width: 420px;
}

button.video-btn {
  margin-top: 32px;
  cursor: pointer;
}

.video-btn {
  width: 207px;
  height: 36px;
  padding: 11px 24px 10px;
  border-radius: 18px;
  border: solid 1px #17e;
  color: #137bef;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: bold;
  font-family: "Lato", sans-serif;
  background-color: #fafafa;
}

.services__item {
  padding: 20px;
}

/* ......................Recommends block..................... */

.recommends-block {
  position: relative;
}

.recommends-block__body {
  padding: 30px;
  position: relative;
  z-index: 2;
  max-width: 545px;
  margin-left: 51%;
}

.recommends-block__title {
  width: 383px;
  height: 128px;
  margin: 0 177px 16px 0;
  font-size: 52px;
  font-weight: 300;
  font-family: "Lato", sans-serif;
  line-height: 1.23;
  letter-spacing: normal;
  color: #000;
}

.recommends-block__title strong {
  color: #fff;
  font-weight: 300;
  font-family: "Lato", sans-serif;
}

.recommends-block__text {
  width: 560px;
  font-size: 16px;
  font-family: "Lato", sans-serif;
  line-height: 1.5;
  letter-spacing: 0.2px;
  color: #fff;
  margin-bottom: 32px;
}

.recommends-block__desc {
  width: 560px;
  margin-bottom: 32px;
  font-size: 16px;
  font-family: "Lato", sans-serif;
  line-height: 1.5;
  letter-spacing: 0.2px;
  color: #fff;
}

.recommends-block__image {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  object-fit: cover;
}

.qr__container {
  display: flex;
  align-items: center;
}

.qr-desc p {
  font-size: 12px;
  font-family: "Lato", sans-serif;
  color: #000;
  line-height: 1.33;
  text-transform: uppercase;
}

.qr-line {
  width: 32px;
  height: 1px;
  border-radius: 1px;
  background-color: #000;
  margin-left: 16px;
  margin-right: 20px;
}
/* ..................Sponsors Section................... */

.sponsors-block {
  height: 70vh;
  position: relative;
}

.sponsors-block__body {
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 100px;
  padding-bottom: 80px;
  text-align: center;
  width: 70%;
  margin: auto;
}

.sponsors-block__title {
  height: 128px;
  font-size: 52px;
  font-weight: 300;
  font-family: "Lato", sans-serif;
  line-height: 1.23;
  letter-spacing: normal;
  color: #000;
}

.sponsors-block__body img {
  margin-bottom: 30px;
}

.sponsors-block__text {
  max-width: 775px;
  height: 48px;
  font-family: Lato;
  font-size: 16px;
  font-style: italic;
  font-family: "Lato", sans-serif;
  line-height: 1.5;
  letter-spacing: 0.21px;
  color: #434f5f;
  margin-bottom: 30px;
}

.sponsors-block__desc {
  max-width: 775px;
  margin-bottom: 32px;
  font-size: 16px;
  font-family: "Lato", sans-serif;
  line-height: 1.5;
  letter-spacing: 0.2px;
  color: #000;
}

.sponsors-block__desc p {
  margin-bottom: 16px;
  font-size: 16px;
  font-weight: 300;
  font-family: "Lato", sans-serif;
  color: #000;
}

.sponsor-companies img {
  width: 200px;
  height: 60px;
}

.sponsors-block__image {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  object-fit: cover;
}
/* ....................Brands Block................... */

.brands-block {
  position: relative;
}

.brands-block__body {
  position: relative;
  z-index: 2;
  margin-top: 100px;
}

.brands-block__title h1 {
  width: 420px;
  font-size: 42px;
  font-weight: 300;
  font-family: "Lato", sans-serif;
  line-height: 1.24;
  letter-spacing: normal;
  text-align: center;
  color: #137bef;
  margin: auto;
}

.brands-block__desc {
  width: 560px;
  margin-bottom: 32px;
  font-size: 16px;
  font-family: "Lato", sans-serif;
  line-height: 1.5;
  letter-spacing: 0.2px;
  color: #000;
}

.brands-block__image {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  object-fit: cover;
}

.brands__column {
  width: 90%;
  display: flex;
  justify-content: space-between;
  margin: auto;
  margin-top: 100px;
}

.brands__item {
  width: 230px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

h3.item-brands__text {
  width: 260px;
  height: 64px;
  font-size: 18px;
  font-weight: 300;
  font-family: "Lato", sans-serif;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: center;
  color: #000;
}

.socials {
  width: 100%;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 120px;
}

.socials h1 {
  font-size: 24px;
  font-weight: 300;
  font-family: "Lato", sans-serif;
  line-height: 1.33;
  text-align: center;
  color: #17e;
}

.media-container {
  width: 152px;
  height: 94px;
  border-radius: 10px;
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.05);
  border: solid 1px rgba(32, 131, 240, 0.02);
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 32px;
  margin-bottom: 102px;
}

.media-container img {
  width: 32px;
  height: 32px;
  margin-bottom: 10px;
}

.media-container a {
  text-decoration: none;
  color: #000;
  font-weight: 300;
  font-family: "Lato", sans-serif;
}

.instagram-links {
  width: 54%;
  display: flex;
  justify-content: space-between;
}

.sponsor-companies {
  margin-top: 30px;
  margin-bottom: 30px;
  display: flex;
}

.item-service__icon img {
  cursor: pointer;
  width: 100px;
  height: 100px;
}

.item-service__text {
  margin-top: -30px;
  padding: 25px;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.03);
  border: solid 1px rgba(34, 134, 241, 0.03);
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: 300;
  font-family: "Lato", sans-serif;
  line-height: 1.5;
  letter-spacing: 0.2px;
  width: 260px;
  max-height: 132px;
}

.item-service__text:hover {
  color: #17e;
  cursor: pointer;
}

#item-one:hover,
#item-two:hover,
#item-three:hover, 
#item-four:hover, 
#item-five:hover, 
#item-six:hover, 
#item-seven:hover, 
#item-eight:hover, 
#item-nine:hover, 
#item-ten:hover, 
#item-eleven:hover, 
#item-twelve:hover, 
#item-thirteen:hover, 
#item-fourteen:hover, 
#item-fifteen:hover, 
#item-sixteen:hover, 
#item-seventeen:hover, 
#item-eighteen:hover,
#item-nineteen:hover {
  transition: 0.2s all ease-in-out;
  transform: scale(105%);
}
